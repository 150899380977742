/* eslint-disable no-unused-vars */
/**
 * @file play-toggle-ext.js
 */
import videojs from 'video.js';

export function isPromise(value: any) {
  return value !== undefined && value !== null && typeof value.then === 'function';
}

/**
 * Silence a Promise-like object.
 *
 * This is useful for avoiding non-harmful, but potentially confusing "uncaught
 * play promise" rejection error messages.
 *
 * @param  {Object} value
 *         An object that may or may not be `Promise`-like.
 */
export function silencePromise(value: any) {
  if (isPromise(value)) {
    value.then(null, (e: Error) => {
      console.error(e);
    });
  }
}

const Button = videojs.getComponent('Button');

class PlayToggleExt extends Button {
  /**
   * Creates an instance of this class.
   *
   * @param {Player} player
   *        The `Player` that this class should be attached to.
   *
   * @param {Object} [options={}]
   *        The key/value store of player options.
   */
  constructor(player: any, options: any = {}) {
    super(player, options);

    // show or hide replay icon
    options.replay = options.replay === undefined || options.replay;

    this.on(player, 'play', () => this.handlePlay());
    this.on(player, 'pause', () => this.handlePause());

    if (options.replay) {
      this.on(player, 'ended', () => this.handleEnded());
    }
  }

  /**
   * Builds the default DOM `className`.
   *
   * @return {string}
   *         The DOM `className` for this object.
   */
  buildCSSClass() {
    return `vjs-play-control ${super.buildCSSClass()}`;
  }

  /**
   * This gets called when an `PlayToggle` is "clicked". See
   * {@link ClickableComponent} for more detailed information on what a click can be.
   *
   * @param {EventTarget~Event} [event]
   *        The `keydown`, `tap`, or `click` event that caused this function to be
   *        called.
   *
   * @listens tap
   * @listens click
   */
  handleClick() {
    if (this.player_.paused()) {
      silencePromise(this.player_.play());
    } else {
      this.player_.pause();
    }
  }

  /**
   * This gets called once after the video has ended and the user seeks so that
   * we can change the replay button back to a play button.
   *
   * @param {EventTarget~Event} [event]
   *        The event that caused this function to run.
   *
   * @listens Player#seeked
   */
  handleSeeked() {
    this.removeClass('vjs-ended');

    if (this.player_.paused()) {
      this.handlePause();
    } else {
      this.handlePlay();
    }
  }

  /**
   * Add the vjs-playing class to the element so it can change appearance.
   *
   * @param {EventTarget~Event} [event]
   *        The event that caused this function to run.
   *
   * @listens Player#play
   */
  handlePlay() {
    this.removeClass('vjs-ended');
    this.removeClass('vjs-paused');
    this.player_.removeClass('vjs-show-big-play-button-on-pause');
    this.addClass('vjs-playing');
    this.controlText('Pause');
  }

  /**
   * Add the vjs-paused class to the element so it can change appearance.
   *
   * @param {EventTarget~Event} [event]
   *        The event that caused this function to run.
   *
   * @listens Player#pause
   */
  handlePause() {
    this.removeClass('vjs-playing');
    this.addClass('vjs-paused');
    this.player_.addClass('vjs-show-big-play-button-on-pause');
    this.controlText('Play');
  }

  /**
   * Add the vjs-ended class to the element so it can change appearance
   *
   * @param {EventTarget~Event} [event]
   *        The event that caused this function to run.
   *
   * @listens Player#ended
   */
  handleEnded() {
    this.removeClass('vjs-playing');
    this.addClass('vjs-ended');
    // on the next seek remove the replay button
    this.one(this.player_, 'seeked', () => this.handleSeeked());
  }
}

/**
 * The text that should display over the `PlayToggle`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
(PlayToggleExt.prototype as any).controlText_ = 'Play';

export default PlayToggleExt;
