/**
 * @file control-bar.js
 */

import videojs from 'video.js';
import PlayToggle from './PlayToggle';

const Component = videojs.getComponent('Component');

/**
 * Container of main controls.
 *
 * @extends Component
 */
class ControlBar extends Component {
  /**
   * Create the `Component`'s DOM element
   *
   * @return {Element}
   *         The element that was created.
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-control-bar',
      dir: 'ltr',
    });
  }
}

/**
 * Default options for `ControlBar`
 *
 * @type {Object}
 * @private
 */
ControlBar.prototype.options_ = {
  children: [
    'playToggle',
    'currentTimeDisplay',
    'progressControl',
    //  'timeDivider',
    'durationDisplay',
    'liveDisplay',
    'seekToLive',
    'remainingTimeDisplay',
    'customControlSpacer',
    'playbackRateMenuButton',
    'chaptersButton',
    'descriptionsButton',
    'subsCapsButton',
    'audioTrackButton',
    'volumePanel',
    'fullscreenToggle',
  ],
};

// if ('exitPictureInPicture' in document) {
//   ControlBar.prototype.options_.children.splice(
//     ControlBar.prototype.options_.children.length - 1,
//     0,
//     'pictureInPictureToggle',
//   );
// }

export const install = () => {
  Component.registerComponent('ControlBar', ControlBar);
  Component.registerComponent('PlayToggle', PlayToggle);
};
