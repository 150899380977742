import { useState, useCallback, useRef, MutableRefObject, useMemo } from 'react';
import videojs from 'video.js'; // 只用到了类型声明
import { install } from './ControlBar';
// import { CLIENT_TYPE, getClientTypeSync } from '@cap/utils';

export type IVideoPlayer = videojs.Player;

interface IParams {
  ref: MutableRefObject<HTMLVideoElement | undefined>;
  hideControl?: boolean;
  events?: Object;
  showBigButton?: boolean;
  onReady?: (player: IVideoPlayer) => void;
  onPlay?: (player: IVideoPlayer) => void;
  onEnded?: (player: IVideoPlayer) => void;
  onPause?: (player: IVideoPlayer) => void;
}

export function useVideoPlayer(params: IParams) {
  const { ref, hideControl, events, onReady, onPlay, onEnded, onPause, showBigButton } = params;
  const playerRef = useRef<IVideoPlayer>();
  const [isWaiting, setIsWaiting] = useState(true);

  const init = useCallback(
    (autoplay?: boolean, loop?: boolean): Promise<IVideoPlayer> => {
      if (playerRef.current) return Promise.resolve(playerRef.current);
      // eslint-disable-next-line no-async-promise-executor
      return new Promise((resolve, reject) => {
        // import('@cap/videojs-vhs/src/index' as any)
        // import('@cap/videojs-vhs')
        import('video.js')
          .then((module) => {
            install();
            const VideoJs = module.default;
            setTimeout(async () => {
              const player: IVideoPlayer = VideoJs(
                ref?.current as HTMLVideoElement,
                {
                  responsive: true,
                  // aspectRatio: 'auto', // 这个会强制让视频按照比例进行播放
                  preload: 'auto',
                  muted: autoplay,
                  /**
                   * 适合响应式网站
                   * Fill mode will make the player fit and fill out its container. This is often useful if you have a responsive website and already have a container for Video.js that resizes properly to your design. It can be set either via a class or an option.
                   */
                  fill: true,
                  autoplay,
                  children: [
                    'mediaLoader',
                    'posterImage',
                    'textTrackDisplay',
                    'loadingSpinner',
                    'liveTracker',
                    'controlBar',
                    'errorDisplay',
                    'textTrackSettings',
                    'resizeManager',
                  ],
                  bigPlayButton: showBigButton || false,
                  controlBar: {
                    remainingTimeDisplay: false,
                    playToggle: true, // !hideControl,
                    progressControl: {},
                    fullscreenToggle: true,
                    playbackRateMenuButton: false,
                    pictureInPictureToggle: false,
                    volumePanel: {
                      volumeControl: {
                        vertical: true,
                      },
                      inline: false,
                    },
                  },
                  plugins: {
                    // qualitySelector: { enabled: false },
                  },
                },
                () => {
                  onReady?.(player);
                  resolve(player);
                },
              );
              if (hideControl) {
                player.controlBar.hide();
              }
              playerRef.current = player;
              Object.keys(events || {}).forEach((key) => {
                // eslint-disable-next-line no-prototype-builtins
                if (Object.prototype.hasOwnProperty.call(events, key)) {
                  // @ts-ignore
                  const handler = events[key];
                  player.on(key, handler);
                }
              });
              player.on('play', () => {
                setIsWaiting(false);
                onPlay?.(player);
              });
              player.on('ended', () => {
                // 保持循环播放
                if (loop) {
                  player.play();
                }
                onEnded?.(player);
              });
              player.on('pause', () => {
                onPause?.(player);
              });
            });
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    [ref, hideControl, events, onReady, onEnded, onPause, onPlay, showBigButton],
  );

  const play = useCallback(
    async (source: string, poster?: string, autoplay?: boolean, loop = true) => {
      if (!playerRef.current) {
        await init(autoplay, loop);
      }
      const player = playerRef.current;
      // const currPoster = convertPoster(poster || '');
      poster && player?.poster(poster);
      if (!source) return;
      player?.src(source);

      // if (CLIENT_TYPE.weixin === getClientTypeSync(navigator.userAgent)) {
      //   try {
      //     (window as any).WeixinJSBridge.invoke('getNetworkType', {}, () => {
      //       !!autoplay && player?.play();
      //     });
      //   } catch (error) {
      //     console.log(error);
      //   }
      // } else {
      //   !!autoplay && player?.play();
      // }
    },
    [init],
  );

  const dispose = useCallback(() => {
    const player = playerRef.current;
    if (!player) return;
    player.dispose();
    playerRef.current = undefined;
  }, []);

  const pause = useCallback(() => {
    setIsWaiting(true);
    playerRef?.current?.pause();
  }, []);

  const unpause = useCallback(() => {
    setIsWaiting(false);
    playerRef?.current?.play();
  }, []);

  return {
    isWaiting,
    play,
    dispose,
    pause,
    unpause,
  };
}

interface Params {
  naturalWidth?: string;
  naturalHeight?: string;
}
export function usePlayerSize(params: Params) {
  const { naturalWidth, naturalHeight } = params;
  return useMemo(() => {
    let containerStyle;
    /* 默认为16:9的尺寸 */
    let paddingTop = '56.25%';
    if (naturalWidth && naturalHeight) {
      const naturalWidthNum = parseInt(naturalWidth, 10);
      const naturalHeightNum = parseInt(naturalHeight, 10);
      if (naturalHeightNum > naturalWidthNum) {
        const verticalWidth = 400;
        paddingTop = `${((100 * naturalHeightNum) / naturalWidthNum).toFixed(2)}%`;
        containerStyle = {
          width: `${verticalWidth}px`,
          margin: 'auto',
        };
      }
    }
    const occupyStyle = { paddingTop };
    return {
      occupyStyle,
      containerStyle,
    };
  }, [naturalWidth, naturalHeight]);
}
