import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';

import { IVideoPlayer, usePlayerSize, useVideoPlayer } from './use-video-player';

import '@cap/videojs-vhs/dist/vjs.css';
// import 'video.js/dist/video-js.min.css';
import style from './VideoPlayer.module.scss';

interface IProps {
  source: string;
  poster?: string;
  autoplay?: boolean; // 是否自动播放
  loop?: boolean; // 是否循环播放
  hideControl?: boolean;
  showBigButton?: boolean; // 是否展示bigButton
  /* 视频的原始尺寸 */
  naturalWidth?: string;
  naturalHeight?: string;
  events?: Object;
  onReady?: (player: IVideoPlayer) => void;
  onPlay?: (player: IVideoPlayer) => void;
  onEnded?: (player: IVideoPlayer) => void;
  onPause?: (player: IVideoPlayer) => void;
}

const VideoPlayer = (props: IProps) => {
  const {
    source,
    poster,
    autoplay,
    hideControl,
    events,
    onReady,
    onPlay,
    onEnded,
    onPause,
    showBigButton,
    naturalWidth,
    naturalHeight,
    loop = true,
  } = props;

  const { occupyStyle, containerStyle } = usePlayerSize({ naturalHeight, naturalWidth });

  const videoClass = classnames('dxp-skin-layout h-full w-full', {
    [`video-layout-hide-control`]: hideControl,
  });

  const videoRef = useRef<HTMLVideoElement>();
  const videoplayerClass = classnames(style.videoplayerbox, 'relative text-lg lg:text-2xsm');
  const { play, dispose, unpause, isWaiting } = useVideoPlayer({
    ref: videoRef,
    showBigButton,
    events,
    hideControl,
    onReady,
    onPlay,
    onEnded,
    onPause,
  });

  useEffect(() => {
    play?.(source, poster, autoplay, loop);
    return () => {
      dispose();
    };
  }, [source, poster, autoplay, play, dispose, loop]);

  const handleToPlay = () => {
    unpause?.();
  };

  return (
    <div className={videoplayerClass} style={containerStyle}>
      <div className={style.playercontainer}>
        <div className={style.playerBox}>
          <div className="video-js dxp-skin h-full w-full">
            <video
              id="player"
              preload="metadata"
              className={videoClass}
              crossOrigin="anonymous"
              playsInline
              controls
              autoPlay={autoplay}
              ref={videoRef as any}
              muted
            >
              <p>您的浏览器不支持 video 标签</p>
            </video>
            {isWaiting ? (
              <div
                onClick={handleToPlay}
                className="absolute bottom-0 left-0 right-0 top-0 z-50 cursor-pointer bg-transparent text-white"
              />
            ) : null}
          </div>
        </div>
        <div style={occupyStyle} />
      </div>
    </div>
  );
};
export default VideoPlayer;
